<template>
  <div>
    <Header> 公告详情 </Header>

    <div class="select">
      <div
        v-for="list in navList"
        :key="list.type"
        @click="ternary(list.type)"
        :class="add == list.type ? 'select_tow' : 'select_text'"
      >
        {{ list.name }}
      </div>
    </div>
    <div class="shadow"></div>

    <div v-for="item in getNotice" :key="item.key">
      <div class="gonggao" @click="announcement(item.id)">
        <img src="../../../static/image/gonggao.png" alt="" />
        <div class="gonggao_box">
          <div class="gonggao_text">
            {{ item.title }}
          </div>
          <div class="gonggao_item">{{ item.created_at }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getNotice: [],
      add: 0,
      navList: [],
      text: [
        {
          typeid: 0,
          name: '全部公告',
        },
        {
          typeid: 3,
          name: '平台公告',
        },
        {
          typeid: 1,
          name: '上新公告',
        },
        {
          typeid: 2,
          name: '舆情公告',
        },
      ],
    };
  },
  methods: {
    // 公告详情
    announcement(e) {
      this.$router.push({ path: '/Banner', query: { id: e } });
    },
    // 根据typeid显示不同内容
    ternary(e) {
      this.add = e;
      let params = {
        pageindex: 1,
        pagesize: 100,
        type: e,
      };
      this.$api.getNotice(params).then((res) => {
        if (res.code == 0) {
          this.getNotice = res.data;
        }
      });
    },
  },
  mounted() {
    let params = {
      pageindex: 1,
      pagesize: 100,
      type: 0,
    };
    this.$api.getNotice(params).then((res) => {
      if (res.code == 0) {
        this.getNotice = res.data;
      }
    });

    this.$api.getNoticeTypeList().then((res) => {
      if (res.code == 0) {
        this.navList = res.data;
      }
    });
  },
};
</script>
<style lang="less" scoped>
#top {
  padding-left: 15px;
}
.all {
  margin-left: 20px;
}
.select {
  display: flex;
  justify-content: center;
  .select_text {
    flex: 1;
    line-height: 50px;
    height: 50px;
    color: #999999;
    font-size: 14px;
    text-align: center;
  }
  .select_tow {
    text-align: center;
    flex: 1;
    font-weight: 600;
    line-height: 50px;
    height: 50px;
    color: black;
    font-size: 14px;
  }
}
.shadow {
  width: 100%;
  height: 10px;
  background-color: #f8f8f8;
}
.update {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 50px;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 6px;
  img {
    width: 6px;
    height: 10px;
    display: block;
  }
}
.gonggao {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 30px;
  border-bottom: 1px solid #f8f8f8;
  img {
    width: 19px;
    height: 15px;
  }
  .gonggao_text {
    font-size: 13px;
  }
  .gonggao_item {
    font-size: 12px;
    color: #999999;
    line-height: 42px;
    margin-bottom: 7px;
  }
}
</style>
